<template>
  <div>
    <div class="mass-action-settings">
      <el-popconfirm
        confirm-button-text='Да'
        cancel-button-text='нет'
        icon="el-icon-question"
        title="Вы уверены, что хотите ознакомить Сотрудников с оценкой?"
        @confirm="doAction"
      >
        <el-button
          slot="reference"
          size="small"
          type="primary"
        >
          Запустить
        </el-button>
      </el-popconfirm>
    </div>
    <mass-action-result
      v-if="result"
      :result="result"
    ></mass-action-result>
  </div>
</template>

<script>
import {ActionTemplateMixin} from "@/components/massActions/actionTemplates/ActionTemplateMixin";
import MassActionResult from "@/components/massActions/MassActionResult.vue";

export default {
  name: "card-enable-total-evaluations-visibility-action",
  mixins: [ActionTemplateMixin],
  components: {MassActionResult},
  data() {
    return {}
  },
  methods: {},
}
</script>
